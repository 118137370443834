@import "main-bx";

.checkbox-block {
    &__input {
        display: none;

        &:checked {
            + label {
                
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &__label {
        position: relative;
        display: flex;
        align-items: center;
        font-family: $TacticRound;
        font-size: 10px;
        font-weight: 400;
        line-height: 23px;
        color: #F2F2F2;
        cursor: pointer;
        
        a {
            margin-left: 3px;
            color: #F2F2F2;
            transition: 0.35s all;

            &:hover {
                color: $color_red;
            }
        }

        &::before {
            flex-shrink: 0;
            box-sizing: border-box;
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background: transparent;
            border: 1px solid #F2F2F2;
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 6px;
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background-image: url('../img/content/check.svg');
            background-size: 12px 12px;
            transform: translateY(-50%);
            opacity: 0;
            transition: 0.35s all;
        }

        &_black {
            color: $color_black;

            &::before {
                border-color: $color_black;
            }

            &::after {
                background-image: url('../img/content/check-black.svg');
            }

            a {
                color: $color_black;
            }
        }
    }
}