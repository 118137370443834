@import "main-bx";

h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0;
    padding: 0;
}

// .container {
//     @include container();
//     @include sm(max-width, rem(576));
//     @include md(max-width, rem(1100));
//     @include lg(max-width, rem(1306));
// }

body {
    position: relative;
    font-family: $TacticRound;
    color: $color_black;
    width: 100%;
    font-size: 16px;
    overflow-y: scroll;
    overflow-x: hidden;

    &.menu-show {
        main {
            display: none;
        }

        footer {
            display: none;
        }

        .navigation-links {
            display: none;
        }
    }

    @include m-tablet() {

    }
}

.container {
    width: 100%;
    max-width: 1490px; 
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
    // @include m-custom(1300px) {
    //     max-width: 1246px;
    // }
    // @include m-custom(1216px) {
    //     max-width: 1118px;
    // }
    // @include m-custom(1138px) {
    //     max-width: 1006px;
    // }
    @include m-tablet() {
        max-width: 768px;
        padding: 0 24px;
    }
    @include m-phone() {
        max-width: none;
        padding: 0 15px;
    }
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

.fixed {
    position: fixed;
}

:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

* {
    box-sizing: border-box;
}