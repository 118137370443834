@import "main-bx";

.intro {
    &.slick-slider {
        margin-bottom: 0 !important;
    }

    .slick-dots {
        bottom: 25px;

        @include m-phone() {
            bottom: 12px;
        }
    }

    .slick-dots li {
        width: auto;
        height: auto;
        margin: 0 7px;
    }

    .slick-dots .slick-active button {
        background-color: $color_red;
    }

    .slick-dots li button {
        width: 12px;
        height: 12px;
        background-color: #cccccc;
        border-radius: 50%;

        &:before {
            display: none;
        }
    }

    &__item {
        position: relative;
        height: 700px;

        @include m-tablet() {
            height: 570px;
        }

        @include m-phone() {
            height: 520px;
        }
    }

    &__img {
        object-fit: cover;
        width: 100%;
        height: 700px;

        @include m-phone() {
            height: 570px;

            &_left {
                width: auto;
                object-fit: none;
            }
        }

        @include m-phone() {
            height: 520px;
        }
    }

    &__text-block-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 38px;

        @include m-tablet() {
            padding-top: 70px;
        }

        @include m-phone() {
            padding-top: 100px;
        }

        &_left {
            padding-top: 177px;
            align-items: flex-start;

            .intro__title {
                max-width: 610px;
                text-align: left;
            }
        }

        &_left-b {
            padding-top: 38px;
            align-items: flex-start;

            .intro__title {
                max-width: 610px;
                text-align: left;
                color: $color_black;
            }

            .intro__text {
                margin-bottom: 30px;
                color: $color_black;

                @include m-phone() {
                    margin-bottom: 15px;     
                }
            }            

            @include m-tablet() {
                padding-top: 38px;
            }

            @include m-phone() {
                padding-top: 38px;
            }
        }
    }

    &__title {
        max-width: 950px;
        margin-bottom: 15px;
        text-align: center;
        font-family: $TacticRoundExtExd;
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase;

        color: $color_white;

        @include m-phone() {
            font-size: 18px;
            line-height: 24px;        
        }
    }  
    
    &__text {
        max-width: 520px;
        margin-bottom: 15px;
        font-family: $TacticRound;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        color: $color_white;        

        @include m-phone() {
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 22px;        
        }
    }

    &__form {
        max-width: 588px;
        width: 100%;
        padding: 30px;
        background: rgba(255, 255, 255, 0.8);

        @include m-phone() {
            padding: 15px;
        }
    }

    &__desc {
        font-family: $TacticRound;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: $color_black;
    }

    &__row {
        display: flex;
        margin-bottom: 17px;

        @include m-phone() {
            flex-direction: column;
        }
    }

    &__input {
        flex-grow: 1;
        padding-right: 10px;

        @include m-phone() {
            padding-right: 0;
            margin-bottom: 10px;
        }

        input {
            width: 100%;
        }
    }

    &__button {
    }

    &__checkbox-block {
        label {
            font-size: 12px;
        }
    }

    &__count-down-block {
        margin-bottom: 15px;
    }
}