@import "main-bx";

.contact {
    display: flex;

    @media (max-width: 860px) {
        flex-direction: column;
    }

    &__map-block {
        width: 50%;

        @media (max-width: 860px) {
            width: 100%;
            height: 400px;
        }
    }

    &__map {
        width: 100%;
        height: 100%;

        &-up {
            position: fixed;
            z-index: 100;
            right: 60px;
            top: 540px;
            @include m-phone() {
                right: 20px;
                top: 490px;
            }
        }
    }

    &__text-block-wrap {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        padding: 152px 0 152px 0;
        background: $color_black;

        @media (max-width: 860px) {
            width: 100%;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &__text-block {
        max-width: 745px;
        padding-right: 93px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: 1550px) {
            padding-right: 30px;
        }

        @media (max-width: 860px) {
            max-width: 768px;
            width: 100%;
            margin: 0 auto;
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    &__title {
        margin-bottom: 30px;
        font-family: TacticRoundExtExd;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        color: $color_white;

        @include m-phone() {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: $color_white;

        @include m-phone() {
            margin-bottom: 14px;
            font-size: 16px;
            line-height: 20px;
        }

        svg {
            flex-shrink: 0;
            margin-right: 10px;
            color: #ffffff;
            transition: 0.35s all;
        }

        &_phone {
            transition: 0.35s all;

            &:hover {
                color: $color_red;

                svg {
                    color: $color_red;
                }
            }
        }
    }

    &__link {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-decoration: underline;
        color: #000000;
        transition: 0.35s all;

        &:hover {
            color: #0B457F;
        }
    }

    &__button {
        margin-top: 10px;

        @include m-phone() {
            width: 100%;
        }
    }

    [class*="ymaps-2"][class*="-ground-pane"] {
        -webkit-filter: grayscale(100%);
    }
}