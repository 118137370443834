@import "main-bx";

.input {
    padding: 12px 17px;
    
    font-family: $TacticRound;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;

    color: $color_white;

    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    transition: 0.35s all;

    &:focus {
        border-bottom-color: #DC0F19;
    }

    &::placeholder {
        color: #989898;
    }

    &_border {
        color: $color_black;
        border: 1px solid #212529;
    }
}