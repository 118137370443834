@import "main-bx";

.button {
    display: inline-block;
    padding: 11px 32px 13px;
    font-family: $TacticRoundExd;
    font-size: 13px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
    color: $color_white;
    background-color: $color_red;
    border: 1px solid $color_red;
    cursor: pointer;
    transition: 0.35s all;

    &:hover {        
        background-color: #DC0F19;
        border-color: #DC0F19;
    }

    &:active {
        background-color: #B91B23;
        border-color: #B91B23;
    }
    
    &:disabled {
        background-color: #CCCCCC;
    }

    &_clear {
        border: 1px solid #3C4650;
        background-color: transparent;
        color: #3C4650;
        
        &:hover {        
            color: #0B457F;
            border: 1px solid #0B457F;
            background-color: transparent;
        }
        
        &:active {        
            color: #3C4650;
            border: 1px solid #3C4650;
            background-color: transparent;
        }
    }

    &_white {
        color: #B91B23;
        background: #FFFFFF;

        &:hover {
            color: #FFFFFF;
            background: #DC0F19;
            border-color: #DC0F19;
        }

        &:active {
            color: #B91B23;
            background: #FFFFFF;    
            border-color: #B91B23;    
        }
    }
}