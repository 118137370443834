@import "main-bx";

.modal {
    width: 100%;
    max-width: 1030px;

    @include m-tablet() {
        max-width: 400px;
    }

    &__content {
        display: flex;

        @include m-tablet() {
            flex-direction: column;
        }
    }

    &__left-block {
        width: 50%;
        flex-shrink: 0;
        line-height: 0;

        @include m-tablet() {
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__input {
        margin-bottom: 20px;

        input{
            width: 100%;
        }

        @include m-tablet() {
            margin-bottom: 15px;
        }
    }

    &__button {
        margin-bottom: 25px;
    }

    &__right-block {
        display: flex;
        flex-direction: column;
        padding: 50px;

        @include m-tablet() {
            width: 100%;
            padding: 20px;
        }

        h2 {
            margin-bottom: 10px;
            max-width: 286px;
            margin-left: auto;
            margin-right: auto;
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
            text-align: center;
            font-family: $TacticRoundExtExd;

            @include m-tablet() {
                font-size: 20px;
                line-height: 28px;
            }
        }

        p {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            font-family: $TacticRound;
            text-align: center;

            @include m-tablet() {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

.fancybox__content {
    padding: 0 !important;

    .carousel__button {
        top: -34px !important;
        right: -34px !important;
        width: 24px;
        height: 24px;
        background-image: url('../img/content/close.svg') !important;

        svg {
            display: none;
        }

        @include m-tablet() {
            display: none;
        }
    }
}

.selection__step6 {
    margin: 20px;
}
