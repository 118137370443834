@import "main-bx";

.navigation-links {
    position: fixed;
    top: 50%;
    right: 40px;
    
    transform: translateY(-50%);   
    z-index: 110;

    @include m-custom(1500px) {
        right: 20px;
    }
    
    @include m-tablet() {
        display: none;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__link {
        position: relative;
        overflow: hidden;
        margin-bottom: 2px;      
        width: 60px; 
        transition: width 0.35s;

        &_1 {
            &:hover {
                width: 293px;
            }
        }

        &_2 {
            &:hover {
                width: 403px;
            }
        }

        &_3 {
            &:hover {
                width: 362px;
            }
        }    
        
        &:hover {
            .navigation-links__btn {
                background-color: #DC0F19;
            }
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background-color: $color_red;
        z-index: 5;
        margin-left: auto;
    }

    &__text {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 60px;
        padding-left: 39px;
        padding-right: 39px;
        word-wrap: normal;
        
        font-family: $TacticRound;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;

        color: $color_white;
        background-color: $color_red;
        z-index: -1;
        width: 350px;
    }
}