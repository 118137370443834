@import "main-bx";

.countdown {
    display: flex;

    li {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:last-child {
            width: 69px;

            
            @media (max-width: 660px) {
                width: 55px;
            }
        }
    }

    .days,
    .hours,
    .minutes,
    .seconds {
        font-family: $TacticRoundExd;
        font-size: 42px;
        font-weight: 700;
        line-height: 67px;
        color: $color_black;

        @media (max-width: 660px) {
            font-size: 30px;
            line-height: 40px;
        }

        &_text {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $color_black;
            
            @media (max-width: 660px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .seperator {
        margin-top: 11px;
        margin-left: 23px;
        margin-right: 23px;
        font-size: 30px;
        font-weight: 700;
        line-height: 43px;

        color: $color_black;
            
        @media (max-width: 660px) {
            font-size: 30px;
            line-height: 40px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}