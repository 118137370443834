$TacticRound: 'TacticRound', sans-serif;
$TacticRoundExd: 'TacticRoundExd', sans-serif;
$TacticRoundExtExd: 'TacticRoundExtExd', sans-serif;

// ---

$color_white: #ffffff;
$color_black: #212529;
$color_red: #B91B23;

// ---
