@import "main-bx";

.header {
    &__top-container {
        display: flex;
        align-items: center;
        padding-top: 26px;
        padding-bottom: 27px;

        @include m-tablet() {
            padding-top: 10px;
            padding-bottom: 10px;
        }        

        @include m-phone() {
            padding-top: 10px;
            padding-bottom: 10px;        
        }
    }

    &__logo-link {
        line-height: 0;        
        transition: 0.35s all;

        &:hover {
            opacity: 0.7;
        }
    }

    &__logo-link img {
        @media (max-width: 1380px) {
            max-width: 220px;
        }

        @media (max-width: 1090px) { 
            max-width: 200px;
        }
    }

    &__title {
        margin-left: 20px;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-left: 20px;
        font-family: $TacticRoundExd;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        border-left: 1px solid #CCCCCC;

        br {
            display: none;

            @media (max-width: 1200px) { 
                display: block;
            }
        }

        @media (max-width: 1380px) {
            margin-left: 12px;
            padding-left: 12px;
            font-size: 14px;
        }

        @media (max-width: 1200px) {         
            font-size: 12px;
            line-height: 16px;
        }

        @include m-tablet() {
            display: none;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        margin-left: auto;
    }
  
    &__text {
        display: flex;
        align-items: center;
        font-family: $TacticRound;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $color_black;
        transition: 0.35s all;        

        @media (max-width: 1200px) {         
            font-size: 11px;
        }

        @include m-tablet() {
            padding: 5px;
        }

        svg {
            color: $color_black;
            margin-right: 10px;
            transition: 0.35s all;
            
            @include m-tablet() {
                margin-right: 0;
            }
        }

        &:first-child {
            margin-bottom: 4px;            

            @include m-tablet() {
                display: none;
            }
        }

        &_phone:hover {
            color: $color_red;

            svg {
                color: $color_red;
            }
        }

        span {
            @include m-tablet() {
                display: none;
            }
        }
    }

    &__button {
        margin-left: 50px;

        @media (max-width: 1260px) {        
            margin-left: 20px;
        }

        @include m-tablet() {
            display: none;
        }
    }

    &__nav-block {
        background: #F2F2F2;
    }

    &__nav-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
        padding-bottom: 20px;

        @include m-tablet() {
            justify-content: space-between;
            padding-top: 10px;
            padding-bottom: 10px;        
        }

        @include m-phone() {
            padding-top: 10px;
            padding-bottom: 10px;   
        }

        @media (max-width: 550px) {
            overflow-x: scroll;
        }
    }

    &__nav-item {
        margin-left: 70px;
        font-family: $TacticRound;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $color_black;

        transition: 0.35s all;

        @include m-tablet() {
            margin-left: 0;
        }

        @include m-phone() {
            margin-left: 10px;
            margin-right: 10px;  
            white-space: nowrap;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &:hover {
            color: $color_red;
        }
    }
}
