@import "main-bx";

.trade-in {
    padding-top: 65px;
    padding-bottom: 37px;

    @include m-tablet() {
        padding-bottom: 0;
    }    

    @include m-phone() {
        padding-top: 30px;
    }

    &__container {
        display: flex;

        @include m-tablet() {
            flex-direction: column;
        }
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 26px;

        @media (max-width: 1700px) {
            flex-shrink: 0;
            width: 770px;
        }

        @media (max-width: 1350px) {
            width: 570px;
        }

        @include m-tablet() {
            width: 100%;
            padding-right: 0;
        }
    }

    &__title {
        margin-bottom: 40px;
        font-family: $TacticRoundExtExd;
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;        

        @include m-phone() {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 24px;            
        }

        @media (max-width: 1350px) {
            br {
                display: none;
            }
        }        
    }

    &__img-block {
        flex-shrink: 0;
        margin-right: -130px;

        @media (max-width: 1700px) {
            max-width: calc(100% - 770px);
            margin-right: 0;
            flex-grow: 1;
        }

        @media (max-width: 1350px) {
            max-width: calc(100% - 570px);
        }

        @include m-tablet() {
            max-width: none;
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: 650px) {
            flex-direction: column;
        }
    }

    &__item {
        width: 49.3%;
        margin-bottom: 10px;

        @media (max-width: 650px) {
            width: 100%;
        }
    }

    &__button {
        width: 100%;
    }

    &__input {
        width: 100%;
    }
}