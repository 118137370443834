@import "main-bx";

.select2.select2-container {
    width: 100% !important;

    .select2-selection__placeholder {
        color: #989898 !important;
    }

    &.select2-container--open .select2-selection--single {
        border-color: $color_black !important;
        border-radius: 0;
    }

    &.select2-container--open .select2-selection__arrow {
        transform: rotate(180deg);
    }

    .select2-selection--single {
        height: 47px;
        border: 1px solid $color_black;
        border-radius: 0;
        transition: 0.35s border-color;

        &:hover {
            border-color: $color_black;
        }
    }

    .select2-selection__rendered {
        padding-top: 10px;
        padding-bottom: 12px;
        padding-left: 16px !important;
        padding-right: 23px !important;
        font-family: $TacticRound;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px !important;
        color: $color_black !important;
    }

    .select2-selection__arrow {
        display: block;
        position: absolute;
        top: 20px !important;
        right: 11px !important;
        width: 12px !important;
        height: 7px !important;
        background-image: url('../img/content/arrow-down.png');
        background-repeat: no-repeat;
        transition: 0.35s transform;

        b {
            display: none;
        }
    }

}

.select2-container {

    .select2-selection--single span.select2-selection__rendered {
        text-overflow: clip;
        margin-right: 25px;
    }

    .select2-selection__clear {
        display: none;
    }

    .select2-dropdown {        

        &::-webkit-scrollbar {
            width: 5px; 
            background: #F2F2F2;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: #989898;
        }

        border: 1px solid $color_black;
        border-radius: 0;

    }

    .select2-results__option {
        padding: 8px 17px !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        font-family: $TacticRound;

        color: #3C4650;
        transition: 0.35s all;
    }
    
    .select2-results__option--selected {        
        color: #3C4650 !important;
        background-color: transparent !important;
    }

    .select2-results__option--highlighted {
        color: $color_black !important;
        background-color: transparent !important;
    }
}

.select2-container.select2-container--multiple {
    .select2-search {
        display: none;
    }

    .select2-results__options {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .select2-results__option {
        position: relative;
        padding-left: 56px !important;

        &::before {
            flex-shrink: 0;
            position: absolute;
            top: 6px;
            left: 17px;
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            border: 1px solid $color_black;
            transition: 0.35s background-color;
        }
        &.select2-results__option--disabled {
            display: none;
        }
    }

    .select2-results__option--selected {
        &::before {
        }

        &::after {
            flex-shrink: 0;
            content: '';
            position: absolute;
            top: 13px;
            left: 24px;
            display: block;
            width: 12px;
            height: 12px;
            background-image: url('../img/content/check-black.svg');
            background-size: 12px 12px;
        }
    }
}

.select2-container--default .select2-results > ul.select2-results__options {
    max-height: none;
    overflow-y: inherit;
}

.select2-dropdown {
    max-height: 125px;
    overflow: auto;
}

.js-select2-multiple2,
.js-select {
    visibility: hidden;
    height: 40px;
}