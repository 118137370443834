@mixin h1() {
    margin-top: 20px;
    margin-bottom: 20px;
}

@mixin m-laptop(){
    @media (max-width: 1440px) {
        @content
    }
}

@mixin m-tablet-wide(){
    @media (max-width: 1280px) {
        @content
    }
}

@mixin m-tablet(){
    @media (max-width: 1023px) {
        @content
    }
}

@mixin m-phone(){
    @media (max-width: 767px) {
        @content
    }
}

@mixin m-custom($maw){
    @media (max-width: $maw) {
        @content
    }
}

@mixin m-custom-min($min){
    @media (min-width: $min) {
        @content
    }
}