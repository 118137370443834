@import "main-bx";

.in-stock {
    padding-top: 100px;
    padding-bottom: 53px;

    @include m-tablet() {
        padding-top: 60px;    
    }

    @include m-phone() {
        padding-top: 40px;
        padding-bottom: 25px;        
    }

    &__title {
        margin-bottom: 30px;
        font-family: $TacticRoundExtExd;
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;

        @include m-phone() {
            font-size: 18px;
            line-height: 24px;            
        }
    }

    &__filter-wrap {
        padding-bottom: 5px;
        margin-bottom: 25px;

        @media (max-width: 1300px) {
            overflow-x: auto;
            overflow-y: hidden;

            &::-webkit-scrollbar {
                height: 7px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: none;
                background-color: rgba($color: #EFF2F4, $alpha: .4);
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgb(199, 199, 199);
            }
        }
    }

    &__filter {
        display: flex;
        border-bottom: 1px solid #CCCCCC;        
    }

    &__filter-item {
        position: relative;
        margin-right: 30px;
        padding: 0 30px 10px;
        font-family: $TacticRoundExtExd;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        background-color: transparent;
        white-space: nowrap;
        border: none;
        
        cursor: pointer;        

        @include m-phone() {
            margin-right: 10px;
            font-size: 13px;
            line-height: 15px;  
            padding: 0 10px 10px;          
        }

        &.active {
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #DC0F19;
            }
        }
    }

    &__content-item {
        display: none;

        &.active {
            display: block;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5%;
        margin-right: -0.5%;
    }

    &__item {
        width: 24%;
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 20px;

        @media (max-width: 1150px) {
            width: 32.33%;
        }

        @include m-tablet() {
            width: 49%;
            margin-bottom: 10px;
        }        

        @media (max-width: 600px) {
            width: 99%;
        }
    }

    &__img-wrap {
        line-height: 0;
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__text-block {
        padding: 20px;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #CCCCCC;

        @include m-tablet() {
            padding: 12px;
        }
    }

    &__type {
        display: inline-block;
        margin-bottom: 10px;
        padding: 3px 6px;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        color: $color_white;
        background: $color_red;

        &_green {
            background-color: #27AE60;
        }
    }

    &__caption {
        margin-bottom: 10px;
        font-family: $TacticRoundExtExd;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;

        @media (max-width: 600px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__text {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;

        @media (max-width: 600px) {
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 18px;
        }
    }

    &__button {
        width: 100%;
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }

    &__desc {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        font-size: 12px;
        font-weight: 400;
        line-height: 23px;

        svg {
            margin-right: 5px;
        }
    }
}