@font-face {
    font-family: 'TacticRound';
    src: url('../fonts/TacticRound/TacticRound-Reg.woff2') format('woff2'),
            url('../fonts/TacticRound/TacticRound-Reg.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TacticRoundExd';
    src: url('../fonts/TacticRound/TacticRoundExd-Med.woff2') format('woff2'),
            url('../fonts/TacticRound/TacticRoundExd-Med.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TacticRoundExtExd';
    src: url('../fonts/TacticRound/TacticRoundExtExd-Bld.woff2') format('woff2'),
            url('../fonts/TacticRound/TacticRoundExtExd-Bld.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}