@import "main-bx";

.footer {
    &__top-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 42px;
        padding-bottom: 40px;

        @include m-tablet() {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 30px;
            padding-bottom: 15px;
        }

        @include m-phone() {
            padding-top: 20px;
            padding-bottom: 5px;        
        }
    }

    &__logo-link {
        transition: 0.35s all;

        &:hover {
            opacity: 0.7;
        }
    }

    &__col {
        &_end {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            @media (max-width: 1200px) {
                .footer__row {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }

            @include m-tablet() {
                align-items: flex-start;

                .footer__row {
                    align-items: flex-start;
                }
            }
        }

        @include m-tablet() {
            margin-bottom: 15px;
        }

        @include m-phone() {
            width: 100%;
        }
    }

    &__row {
        display: flex;
        align-items: center;
    }

    &__title {
        margin-left: 20px;
        padding-left: 20px;
        font-family: $TacticRoundExd;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border-left: 1px solid #CCCCCC;

        @include m-phone() { 
            margin-left: 10px;
            padding-left: 10px;       
            font-size: 12px;
            line-height: 14px;
        }
    }

    &__nav {
        display: flex;
        margin-top: 24px;
        
        @include m-tablet() {
            margin-top: 10px;
            flex-wrap: wrap;
        }
    }

    &__nav-link {
        margin-right: 40px;
        font-family: $TacticRound;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        color: $color_black;
        transition: 0.35s all;

        &:hover {
            color: $color_red;
        }

        @include m-tablet() {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        @include m-phone() {   
            font-size: 12px;
            line-height: 16px; 
        }
    }

    &__text {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        color: $color_black;

        @include m-phone() { 
            font-size: 12px;
            line-height: 16px;         
        }

        svg {
            margin-right: 10px;
        }

        &:last-child {
            margin-left: 20px;

            @include m-tablet() {
                margin-left: 0;
                margin-top: 5px;
            }
        }

        &_phone {            
            transition: 0.35s all;

            &:hover {
                color: $color_red;
            }
        }
    }

    &__button {
        margin-top: 11px;
        
        @include m-phone() { 
            width: 100%;
        }
    }

    &__bottom-block {
        background-color: #F2F2F2;
    }

    &__bottom-container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        padding-bottom: 21px;

        @include m-tablet() {
            padding-top: 10px;
            padding-bottom: 10px;            
        }        

        @include m-phone() {
            padding-top: 10px;
            padding-bottom: 10px;        
        }
    }

    &__link {
        margin-right: 20px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #000000;
        text-transform: uppercase;
        transition: 0.35s all;

        @include m-phone() { 
            font-size: 10px;
            line-height: 12px;        
        }

        &:hover {
            opacity: 0.7;
        }

        @include m-tablet() {            
            padding-top: 5px;
            padding-bottom: 5px;  
        }
    }
}
