@import "main-bx";

.service {
    background-image: url('../img/content/service-bg.jpg');
    background-size: cover;

    &__container {
        padding-top: 180px;
        padding-bottom: 325px;

        @media (max-width: 1600px) {            
            padding-bottom: 200px;
        }

        @include m-tablet() {            
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @include m-phone() {            
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &__title {
        margin-bottom: 10px;
        font-family: $TacticRoundExtExd;
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;
        color: $color_white;

        @include m-phone() {
            margin-bottom: 20px;  
            font-size: 18px;
            line-height: 24px;        
        }
    }

    &__text {
        margin-bottom: 30px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: $color_white;

        @include m-phone() { 
            margin-bottom: 20px; 
            font-size: 16px;
            line-height: 20px;        
        }
    }

    &__row {
        display: flex;
        margin-bottom: 10px;

        @include m-phone() {     
            flex-direction: column;
        }
    }

    &__button {
        margin-left: 10px;

        @include m-phone() {  
            margin-left: 0;
            width: 100%;
        }
    }

    &__input {
        width: 325px;

        @include m-phone() {  
            width: 100%;
            margin-bottom: 10px;
        }
    }
}