@import "main-bx";

.model {
    padding-top: 100px;

    @media (max-width: 1250px) {
        padding-top: 60px;
    }

    @include m-tablet() {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @include m-tablet() {
        padding-bottom: 10px;    
    }

    &__title {
        margin-bottom: 40px;
        text-align: center;
        font-family: $TacticRoundExtExd;
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;

        @media (max-width: 1250px) {
            margin-bottom: 10px;
        }

        @include m-phone() {
            font-size: 18px;
            line-height: 24px;            
        }
    }

    &__filter-wrap {
        position: sticky;
        top: 0;
        z-index: 100;

        padding-bottom: 5px;
        padding-top: 20px;
        margin-bottom: 45px;
        background-color: $color-white;

        @media (max-width: 1300px) {
            margin-bottom: 25px;
            overflow-x: auto;
            overflow-y: hidden;

            &::-webkit-scrollbar {
                height: 7px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: none;
                background-color: rgba($color: #EFF2F4, $alpha: .4);
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgb(199, 199, 199);
            }
        }

        @media (max-width: 1250px) {
            padding-bottom: 10px;
        }
    }

    &__filter {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #CCCCCC;    
        
        @media (max-width: 1250px) {
            justify-content: flex-start;
        }
    }

    &__filter-item {
        position: relative;
        margin-right: 30px;
        padding: 0 30px 10px;
        font-family: $TacticRoundExtExd;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        background-color: transparent;
        white-space: nowrap;
        border: none;
        color: $color_black;        
        cursor: pointer;   
        
        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            @media (max-width: 1250px) {
                margin-left: 20px;
            }
            
            @include m-phone() {
                margin-left: 15px;
            }
        }

        @include m-phone() {
            margin-right: 10px;
            font-size: 13px;
            line-height: 15px;    
            
            padding: 0 10px 10px;
        }

        &.active {
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #DC0F19;
            }
        }
    }

    &__img-block {

        @media (max-width: 1440px) {
            max-width: 650px;
        }
        @media (max-width: 1250px) {
            align-items: center;
            max-width: 500px;
        }
        @include m-tablet() {
            max-width: none;
            flex-direction: column;
            margin-bottom: 0;
        }
        display: flex;
        max-width: 790px;
        margin-bottom: 0;
    }

    &__thumbnail {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        max-width: 160px;
        margin-right: 30px;

        @media (max-width: 1440px) {
            margin-right: 20px;
        }

        @media (max-width: 1250px) {
            max-width: 100px;
        }

        @include m-tablet() {
            flex-direction: row;
            order: 3;
            max-width: 100%;
            margin-top: 10px;
            margin-left: 0;
            margin-right: 0;
        }

        .slick-slide {

            @include m-tablet() {
                width: auto !important;
            }
            width: 100% !important;
            border: none !important;
            line-height: 0;
        }

        .slick-prev {
            left: 50%;
            top: 2px;
            transform: none;
            transform: translateX(-50%);

            background-image: url('../img/content/arrow-up.svg');
            z-index: 15;

            &::before {
                display: none;
            }

            &:focus,
            &:hover {
                background-image: url('../img/content/arrow-up.svg');
            }
        }

        .slick-next {
            left: 50%;
            top: auto;
            bottom: 1px;
            transform: none;
            transform: translateX(-50%);

            background-image: url('../img/content/arrow-down.svg');
            z-index: 15;

            &::before {
                display: none;
            }

            &:focus,
            &:hover {
                background-image: url('../img/content/arrow-down.svg');
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: block;
            height: 20px;
            background: rgba(#FFFFFF, 0.8);
            z-index: 10;

            @include m-tablet() {
                display: none;
            }
        }

        &::after {
            top: auto;
            bottom: 0;
        }
    }

    &__thumbnail-wrap {
        position: relative;
        margin-top: 2px;
        margin-bottom: 2px;
        line-height: 0;
        cursor: pointer;

        img {
            @media (max-width: 1250px) {
                width: 100%;
                height: auto;
            }
        }

        @include m-tablet() {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 2px;
            margin-left: 2px;
            max-width: 100px;
        }
    }

    &__img-wrap {
        display: flex !important;
        align-items: center;
        width: calc(100% - 190px);
        line-height: 0;
        cursor: pointer;

        @media (max-width: 1440px) {
            width: calc(100% - 180px);
        }

        @media (max-width: 1250px) {
            width: calc(100% - 120px);
        }

        @include m-tablet() {
            width: 100%;
        }

        .slick-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 50px;

            &::before {

                @include m-tablet() {
                    width: 18px;
                    height: 29px;
                    background-size: 18px 29px;
                }
                content: "";
                display: block;
                width: 25px;
                height: 40px;
                background-image: url("../img/content/arrow-left.svg");
                background-size: 25px 40px;
                opacity: 1;
            }
        }
        .slick-prev {
            z-index: 10;
            left: 20px;
        }
        .slick-next {
            z-index: 10;
            right: 20px;

            &::before {
                transform: rotate(180deg);
            }
        }

        img {
            width: 100%;
            height: auto;
            max-height: 370px;
            line-height: 0;
        }
    }

    &__item {
        display: flex;
        margin-bottom: 70px;

        @media (max-width: 1250px) {
            margin-bottom: 30px;
        }

        @include m-tablet() {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }

    &__text-block {
        padding: 30px 30px 10px;
        border: 1px solid #F2F2F2;

        @media (max-width: 1440px) {
            border: none;
        }

        @media (max-width: 1150px) {
            padding: 20px 20px 10px;
        }

        @include m-tablet() {        
            border: 1px solid #F2F2F2;
            border-top: none;
        }
    }

    &__caption {
        margin-bottom: 20px;
        font-family: $TacticRoundExtExd;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
    }

    &__feature-list {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 600px) {    
            flex-direction: column;
        }
    }

    &__feature-item {
        display: flex;
        align-items: center;
        width: 50%;
        margin-bottom: 30px;

        @media (max-width: 1150px) {
            margin-bottom: 15px;
        }

        @media (max-width: 600px) {       
            margin-bottom: 10px;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
    }

    &__icon {
        flex-shrink: 0;
        margin-right: 15px;

        @media (max-width: 1150px) {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }
    }

    &__text {
        font-family: $TacticRoundExd;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;

        @media (max-width: 1150px) {
            font-size: 16px;
            line-height: 20px;        
        }
    }

    &__text-sm {
        font-family: $TacticRoundExd;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;

        @media (max-width: 1150px) {
            font-size: 12px;
            line-height: 16px;        
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        margin-bottom: 30px;
        font-family: $TacticRoundExd;
        font-size: 13px;
        font-weight: 500;
        line-height: 23px;
        color: $color_black;
        transition: 0.35s all;

        @media (max-width: 1150px) {
            margin-bottom: 15px;
        }

        &:hover {
            opacity: 0.7;
        }

        svg {
            margin-left: 10px;
        }
    }

    &__button {
        &:last-child {
            margin-left: 10px;
            padding-left: 18px;
            padding-right: 18px;

            @media (max-width: 600px) { 
                margin-left: 0;
            }
        }
        
        @media (max-width: 600px) { 
            margin-bottom: 10px;
        }
    }

    &__btn-block {
        display: flex;

        @media (max-width: 600px) { 
            flex-direction: column;
        }
    }
}